import React from "react"
import "jquery"
import "@popperjs/core"
import "popper.js"
import "bootstrap"
import "./src/global-styles/app.scss"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
import { OverlayProvider } from "./src/context/overlay-context"
import { HierarchyProvider } from "./src/context/hierarchy-context"
// Prevent fontawesome from dynamically adding its css since we did it manually above
if (typeof window === 'undefined') config.autoAddCss = false;
else config.autoAddCss = true;

export const wrapRootElement = ({ element }) => (
  <HierarchyProvider>
    <OverlayProvider>
      {element}
    </OverlayProvider>
  </HierarchyProvider>
)